<template>
    <section>
        <div class="page-header">
            <h3 class="page-title">
                Sections de la page "Accueil-Beekeeper"
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0);">Accueil-Beekeeper</a></li>
                <li class="breadcrumb-item active" aria-current="page">Beekeeper</li>
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-md-12 grid-margin">
                <div class="card">
                    <div class="card-body">

                        <!-- Créer collection -->
                        <button v-b-modal.modalmd class="mb-4 text-right btn btn btn-gradient-primary btn-fw btn-secondary">Ajouter une collection de slide</button>
                        <b-modal id="modalmd" ref="addModal" title="Créer une nouvelle collection" hide-footer size="md">
                            <form class="forms-sample">
                                <!-- Entités  -->
                                <b-form-group label="Entités"  label-for="entity">
                                    <b-form-select id="entity" v-model="selectedEntitytypes" placeholder="Sélectionnez l'entité à associer" size="lg" >
                                    <b-form-select-option class="select option" value=""></b-form-select-option>
                                    <b-form-select-option class="select option" :value="key.entitytype" v-for="(key,i) in entitytypes" :key="i">{{key.entitytype}}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>

                                 <!-- Nom  -->
                                <b-form-group label="Titre"  label-for="title">
                                    <b-form-select id="title" v-model="selectedTitle" placeholder="Sélectionnez l'entité à associer" size="lg" >
                                    <b-form-select-option class="select option" value=""></b-form-select-option>
                                    <b-form-select-option class="select option" :value="essai" v-for="(essai,t) in entitytypesinfo" :key="t">{{essai}}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>

                                 <!-- description  -->
                                <b-form-group label="Description"  label-for="description">
                                    <b-form-select id="description" v-model="selectedDescription" placeholder="Sélectionnez l'entité à associer" size="lg" >
                                    <b-form-select-option class="select option" value=""></b-form-select-option>
                                    <b-form-select-option class="select option" :value="essai" v-for="(essai,t) in entitytypesinfo" :key="t">{{essai}}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>

                                <div class="d-flex">
                                    <!-- <b-button type="" variant="success" class="mr-2" @click="submitCollection()">Submit</b-button> -->
                                <b-button variant="primary" @click="submitCollection()">Ajouter</b-button>
                                <b-button variant="dark" @click="closeModal()">Annuler</b-button>
                                </div>
                            </form>
                        </b-modal>

                        <!-- créer un slide -->
                        <button v-b-modal.create class="mb-4 text-right btn btn btn-gradient-primary btn-fw btn-secondary">Créer un slide</button>
                        <b-modal id="create" ref="addModal" title="Créer un nouveau slide" hide-footer size="md">
                            <form class="forms-sample">

                                 <!-- Nom  -->
                                <b-form-group label="Titre"  label-for="title">
                                    <b-form-input type="text" id="title" v-model="selectedTitle" placeholder="Titre du slide" size="lg" ></b-form-input>
                                </b-form-group>

                                 <!-- description  -->
                                <b-form-group label="Description"  label-for="description">
                                    <b-form-textarea type="text" :rows="3" :max-rows="6" v-model="selectedDescription"></b-form-textarea>
                                </b-form-group>

                                <div class="d-flex">
                                    <!-- <b-button type="" variant="success" class="mr-2" @click="submitCollection()">Submit</b-button> -->
                                <b-button variant="primary" @click="submitElement()">Ajouter</b-button>
                                <b-button variant="dark" @click="closeElement()">Annuler</b-button>
                                </div>
                            </form>
                        </b-modal>

                        <!-- affichage de l'UI -->
                        <b-carousel id="carousel2" controls indicators background="#ababab" :interval="3000" v-model="slide2" >
                            <b-carousel-slide v-for="(item,z) in figcaption" :key="z">
                                <img slot="img" v-if="item.image" :src="item.image" alt="slide image">
                                <img slot="img" v-else src="../../../assets/images/import/1.jpg" alt="slide image">
                                <h3>{{item.title}}</h3>
                                <p>{{item.description}}</p>
                            </b-carousel-slide>
                        </b-carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const axios = require('axios').default
export default {
    name: 'slide',
    data () {
        return {
            slide2: 0,
            selectedName:'',
            selectedEntitytypes: '',
            keys: [],
            categories:[],
            entitytypes: [],
            selectedEntitytypesInfo: [],
            entitytypesinfo: [],
            selectedTitle: '',
            selectedDescription: '',
            figcaption: [
                {title: 'TITRE', description: 'Description du slide'}
            ],
            submitEntitytype: [],
            tabtest: [
                {test1: 'akory1', test2: 'salama1'},
                {test1: 'akory2', test2: 'akory 2'},
                {test1: 'akory3', test2: 'akory 3'},
            ],
            essai: [
                {akory:'hehe'}
            ]
            // test1:'',
            // test2:''
        }
    },
    watch: {
         selectedEntitytypes (value) {
            // this.getEntitytypesInfo
            // console.log('keys2', this.selectedEntitytypesInfo)
            console.log('value',value);
             axios.get(`https://sites.comolapapaya.com/${this.selectedEntitytypes}`)
            .then ( resGetEntitypesInfo => {
                //éléments à submiter 
                this.submitEntitytype= resGetEntitypesInfo.data.result
                console.log('X', this.submitEntitytype)

                this.entitytypesinfo= Object.keys(resGetEntitypesInfo.data.result[0])
                console.log('keys', this.entitytypesinfo)
                // console.log('entityTypesInfo', resGetEntitypesInfo)
            })
            .catch (errG => console.log('errorG',errG))
        },
        selectedEntitytypesInfo(value) {
            console.log('value entity',value);
        }
    },
    methods: {
        push() {
            // for (let test=0; test< this.tabtest.length; test++) {
            //     console.log(test.test1);
            // }
            for (let x of this.tabtest) {
                // let title = x.test1
                // let description = x.test2
                this.figcaption.push ( {title: x.test1, description: x.test2})
            }
            
            // this.figcaption.push({title:this.essai.akory, description:this.tabtest.test2})
        },
        submitCollection () {
            this.figcaption.pop ()
            // let X= this.selectedTitle
            for ( let param of this.submitEntitytype) {
                this.figcaption.push ({
                    title: param[`${this.selectedTitle}`],
                    description: param[`${this.selectedDescription}`],
                    image: 'https://picsum.photos/200'
                    })
            }
        },
        closeModal () {
            this.selectedEntitytypes= ''
            this.selectedTitle=''
            this.selectedDescription=''
            this.$refs['addModal'].hide()
        },
        submitElement() {
            this.figcaption.push (
                { title: this.selectedTitle , description: this.selectedDescription}
            )
        },
        closeElement() {
            this.selectedTitle=''
            this.selectedDescription=''
            this.$refs['addModal'].hide()
        }
    },
    mounted () {
        // console.log(Object.keys(this.test));
        // console.log(Object.keys(this.categories[0]));
        axios.get('https://sites.comolapapaya.com/entitytypes')
        .then (async resCategories=> {
            // this.keys = resCategories.data.result
            // console.log(resCategories)
            // console.log('keys',resCategories.data.result)
            // this.keys= Object.keys(resCategories.data.result[0])
            this.entitytypes= resCategories.data.result
            // console.log(this.keys);
        })
        .catch(errCategories => console.log(errCategories))
    }
}
</script>